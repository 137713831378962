@import "../../styles/variables.scss";

.header-wrapper {
  width: 100%;
  padding-top: 20px;
  margin: 0 20px;
  border-bottom: 1px solid red;

  @media (max-width: 700px) {
    padding-top: 0;
  }

  &.visibility-hidden {
    visibility: hidden;
    height: 0;
    padding-top: 0;
  }


  .header-content {
    width: 100%;
    height: 70px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 700px) {
      padding: 0 10px;
    }

    .header-left-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .burger-menu {
      display: none;
      font-size: 30px;
      cursor: pointer;
      margin-right: 30px;

      
      @media (max-width: 992px) {
          display: block;
      }
      
      @media (max-width: 700px) {
        margin-right: 10px;
      }

      @media (max-width: 450px) {
        font-size: 24px;
      }

    }


    .login-menu {
      display: flex;
      gap: 20px;
      font-family: $metal-font;
      color: #d4d4d4;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 992px) {
        display: none;
        flex-direction: column;
        gap: 10px;
        background: rgb(18, 18, 18);
        position: absolute;
        top: 90px;
        right: 0px;
        padding: 20px;
        border-radius: 5px;
        z-index: 10;

        &.open {
          display: flex;
        }
      }

      
      @media (max-width: 700px) {
        top: 70px;
      }

      .login-menu-item {
        font-size: 22px;

        @media (max-width: 992px)  {
          text-align: left;
          width: 100%;
        }
      }

      span {
        display: inline-block;
      }

      .acc-name,
      .balance {
        color: $red-color;
        font-size: 20px;

      }
    }

    .header-logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        width: auto;
        height: 65px;
        padding-right: 50px;

        @media (max-width: 700px) {
          height: 40px;
          padding-right: 25px;
        }

        @media (max-width: 450px) {
          padding-right: 15px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .header-menu {
      font-family: $metal-font;
      width: max-content;
      display: flex;
      gap: 20px;

      @media (max-width: 450px) {
        gap: 10px;
      }

      .menu-item {
        color: #d4d4d4;
        font-size: 22px;
        transition: 0.2s linear all;
        cursor: pointer;

        @media (max-width: 450px) {
          font-size: 18px;
        }

        &:hover {
          color: white;
        }
      }
    }
  }
}
