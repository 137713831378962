@import "../../../variables/variables";

.card-slider {
  font-size: 1.2rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 500px;
  margin: 0 auto;

  @media screen and (max-height: 600px) {
    width: 400px;
  }

  &_controls {
    margin-top: 20px;
  }

  &_card {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;

    &_image {
      width: 100%;
      height: auto;
      display: none;

      &.active {
        display: block;
      }
    }

    img {
      width: 100%;
      height: auto;
      max-height: 380px;
      
    }

    video {
      width: 100%;
      height: auto;
      border-radius: 20px;
    }
  }

  &_details {
    font-family: $metal-font;

  }
}


.animated-card--appear {
  width: 100%;
  height: 100%;
  animation-delay: 1s;
  animation: appear 0.5s;
  -webkit-animation: appear 0.5s;
  opacity: 1;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
