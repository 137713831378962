.unpacker-result {
  .rodal-dialog {
    padding: 4rem 5rem;
    background-color: rgba(255, 255, 255, 0);
    background-image: url("../../resourses/Limited_offer.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: max-content;
    position: relative;

    @media screen and (max-height: 600px) {
      padding: 20px;
    }
  }
}

.unpacker-result-wrapper {

}
