@import "../../../styles/variables.scss";

.custom-button {
  padding: 0!important;
  width: 220px;
  min-height: 45px;
  color: white;
  background-image: url("../../../resourses/btn-decor.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  font-family: $metal-font!important;
  font-size: 22px;
  background-color: initial!important;
  border: none;


  &:hover {
    cursor: pointer;
    background-image: url("../../../resourses/button-spec.png");
    background-position: center;
    background-size: 100% 100%;
    transition: 0.1s ease-in-out;
    transform: scale(1.1);
  }

  &.active {
    background-image: url("../../../resourses/button-spec.png");

    @media (max-width: 600px) {
      background-size: 125% 100%;
    }
  }
}
