@import '../variables';

* {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    box-sizing: border-box;
    font-family: $metal-font;
    font-size: 16px;
    line-height: 100%;
  }
  
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: $grey-color;
    background: black;
  }

  button, input, ::placeholder {
    font-size: 16px;
  }
  
    
  a {
    text-decoration: none;
    color: white;
    text-transform: none;
  }
  
  @mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Opera and Firefox */
  }


  *::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #D9D9D9;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background:  $red-color;
  }



