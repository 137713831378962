.staking-block-selector {
    width: 450px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
  
    @media screen and (max-width: 700px) {
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  
    button {
      width: 250px;
  
      @media screen and (max-width: 600px) {
        width: 180px;
        min-width: 180px;
      }
  
      span {
        font-size: 25px;
  
        @media screen and (max-width: 600px) {
          font-size: 17px;
        }
      }
    }
  }
  