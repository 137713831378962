@import "../../styles/variables.scss";


.staking-page-container {
    margin-top: 50px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 120px;
    padding: 0 10px;

    h1 {
        font-size: 46px;
        margin-bottom: 30px;

        @media (max-width: 992px) {
            font-size: 32px;
        }
    }
}